import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Layout } from "../../components/layout"
import { GridTwo, HorSepAnim } from "../../components/grids"
import { H2UppercaseGrey,
         H3Grey,
         H4Grey, } from "../../components/headings"

import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"

function Klappentext( content ) {
    const LinkBlueBlock = styled.div`
        float: right;
        font-size: 2rem;
        font-weight: 2;
        line-height: 40px;
    `
    const HeadingBlueBlock = styled.div`
        width: calc(100% - 100px);
        height: 80px;
        background-color: #00a4b8;
        padding: 20px;
        padding-left: 80px;
        margin-bottom: 15px;
        color: #FFF;
        font-size: 1.2rem;
        cursor: pointer;

        @media screen and (max-width: 813px) {
            width: 100%;
            font-size: 1.1rem;
            padding-left: 30px;
        }
    `
    const TitleBlueBlock = styled.div`
        float: left;
        width: 80%;
        box-sizing: border-box;
        line-height: 40px;
    `

    const ULBlueBlock = styled.div`
        height: fit-content;
        max-height: 0px;
        width: calc(100% - 50px);
        transition: all 1s ease;
        overflow: hidden;

        &.shown {
            max-height: 100vh;
        }

        @media screen and (max-width: 813px) {
            width: 100%;

            &.shown {
                max-height: 200vh;
            }
        }
    `

    const ULStyle = styled.ul`
        list-style: disc;
        padding-left: 80px;
        padding-right: 80px;

        @media screen and (max-width: 813px) {
            width: 100%;
            padding-left: 20px;
            padding-right: 50px;
            font-size: 0.8rem;
        }
    `

    class Toggle extends React.Component {
        constructor(props) {
            super(props);
            this.state = {isToggleOn: false};
            this.heading = props.heading;
            this.listcontent = props.listcontent;
            this.handleClick = this.handleClick.bind(this);


            this.ulReference = null;
            this.setulReferenceRef = element => {
                this.ulReference = element;
            };
        }

        handleClick() {
            this.setState(state => ({
                isToggleOn: !state.isToggleOn
            }));
        }

        render() {
            return(
                <div>
                    <HeadingBlueBlock onClick={this.handleClick}>
                        <TitleBlueBlock>{this.heading}</TitleBlueBlock>
                        <LinkBlueBlock>
                            {this.state.isToggleOn ? '-' : '+'}
                        </LinkBlueBlock>
                    </HeadingBlueBlock>
                    <ULBlueBlock ref={this.setulReferenceRef} className={`${this.state.isToggleOn ? "shown": ""}`}>
                        <ULStyle>
                            {this.listcontent.map((li) => {
                                return (<li>{li}</li>)
                            })}
                        </ULStyle>
                    </ULBlueBlock>
                </div>
            )
        }
    }
    let prep = [];
    let tmpContent = {};
    content.content.map((item) => {
        if (item.nodeType === "heading-4") {
            tmpContent = {};
            prep.push(tmpContent);
            tmpContent.heading = item.content[0].value;
            tmpContent.listItems = [];
        } else {
            if (item.nodeType === "unordered-list") {
                item.content.map((ulitem) => {
                    // unordered list has list items in an array as content
                    // a list item has paragraphs in an array as content
                    tmpContent.listItems.push(ulitem.content[0].content[0].value)
                })
            }
        }
    })
    return (
        <div>
            {prep.map((item) => {
                return (
                    <Toggle heading={item.heading} listcontent={item.listItems} />
                )
            })}
        </div>
    )
}

export default function Career({ data }) {
    const options = {
        renderNode: {
            // [BLOCKS.HEADING_1]: (node, children) => <H4UppercaseGrey>{children}</H4UppercaseGrey>,
            [BLOCKS.HEADING_2]: (node, children) => <H2UppercaseGrey>{children}</H2UppercaseGrey>,
            [BLOCKS.HEADING_3]: (node, children) => <H3Grey>{children}</H3Grey>,
            [BLOCKS.HEADING_4]: (node, children) => <H4Grey>{children}</H4Grey>,
            [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        }
    }

    const careerData = data.allContentfulCareerPage.nodes[0]
    const imgs = data.company.nodes.map(elem => {
        return (
            <Img fluid={elem.childImageSharp.fluid} />
        )
    })

    const TextDiv = styled.div`
        padding: 80px;
        text-align: left;

        @media screen and (max-width: 813px) {
            text-align: left;
            padding: 30px;
            font-size: 0.9rem;
        }
    `

    const ImgDiv = styled.div`
        height: calc(100vh - 200px);

        @media screen and (max-width: 813px) {
            height: 50vh;
        }
    `

    const KlappencontainerSide = styled.div`
        padding: 0px;
        text-align: left;

        @media screen and (max-width: 1700px) and (orientation: landscape) and (min-width: 1000px) {
            display: none;
        }
    `

    const KlappencontainerBelow = styled.div`
        padding: 0px;
        text-align: left;
        display: none;
        margin-top: 15px;
        @media screen and (max-width: 1700px) and (orientation: landscape) and (min-width: 1000px) {
            display: block;
        }
    `

    return (
        <Layout title="Career" lang="en" actSite="career">
            <HorSepAnim height="200px" images={imgs}/>
            <GridTwo
                left={
                    <div>
                        <TextDiv>
                            {documentToReactComponents(careerData.mainText.json, options)}
                        </TextDiv>
                        <KlappencontainerSide>
                            {Klappentext(careerData.companyBenefits.json)}
                        </KlappencontainerSide>
                    </div>
                }
                right={
                    <ImgDiv>
                        <Img fluid={careerData.mainImage.fluid} style={{ height: "100%" }}/>
                    </ImgDiv>
                }
            />
            <KlappencontainerBelow>
                {Klappentext(careerData.companyBenefits.json)}
            </KlappencontainerBelow>
        </Layout>
    )
}

export const query = graphql`
    query {
        allContentfulCareerPage(filter: {node_locale: {eq: "en"}}) {
            nodes {
                companyBenefits {
                    json
                }
                mainText {
                    json
                }
                mainImage {
                    fluid(maxWidth: 1200, quality: 100) {
                        ...GatsbyContentfulFluid
                        src
                    }
                }
            }
        }
        company: allFile(filter: {relativeDirectory: {eq: "company"}}) {
            nodes {
                childImageSharp {
                    fluid(maxWidth: 1150, webpQuality: 100, jpegQuality: 100, pngQuality: 100) {
                        ...GatsbyImageSharpFluid
                        src
                    }
                }
            }
        }
    }
`